import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import WebbDividerSmall from '../webx/webb-divider-sm'

import { GetLocalBusiness, GetLocalUser } from '../../services/srvc-auth-local'

import { GetUserForm } from '../../services/srvc-utilities'
import { AssetListCreator } from '../../services/srvc-assets-realm'

const listFormat = require('../../data.static/data-asset-formats.json').data

export default function AssetsListCreatorModule (props) {

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const navigate = useNavigate()

  const [loader, setLoader] = useState(true)
  const [data, setData] = useState()
  const [filterData, setFilterData] = useState([])
  const [sort, setSort] = useState()

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true)

        const result = await AssetListCreator({
          data: {
            creator: asset.item,
            index: 1,
            items: 25,
            filters: { name: {} }
          }
        })
        if (result.stat) {
          console.log(result.data.list)
          setData(result.data.list)

        }

        setLoader(false)
      }
      fetchData()
    } else {
    }
  }, [])

  useEffect(() => {
    if (props?.search && data) {
      const { formats } = props?.search
      const filterFormats = Object.keys(formats).filter(key => formats[key])
      const dataFilter = data.filter(item =>
        filterFormats.includes(item.feature.format)
      )
      setFilterData(dataFilter)
    }
  }, [props.search])


  const handleClick = async (item) => {
    var unit = data.find(x => x.item == item).units.list[0]
    navigate(`/${asset.role}/units/${unit.item}`)
  }

  if (loader) return <><div className='mx-3'>Please Wait...</div></>
  if (!loader && (!data || data.length === 0))
  return <><div className='mx-3'>No Events Found</div></>

  return (
    <>
      {/* data */}
      <div className=''>
        {data && data.map((item, x) => (
          <div className='d-flex back-color-wite rounded-xd p-2 mb-2 cursor hilite' key={x} onClick={() => handleClick(item.item)}>
            <div className=''>
              <div className=''>
                <img src={item?.media?.link} className='rounded-md' style={{width: '5rem', height: '5rem'}}></img>
              </div>
            </div>
            <div className='ms-3'>
              <p className='text-normal text-bold m-0'>{item.meta.name}</p>
              <p className='text-small m-0'>{item.dates.start}</p>
              <p className='m-0'></p>
              <p className='text-small m-0 text-md'>{item.meta.memo}</p>
            </div>
          </div> 
        ))}
      </div>
      
    </>
  )
}
