// init
import axios from 'axios'
import services from '../data.services/data-services-snbx.json'

const base = services.data.find(x => x.code === 'users.creators').link

// -----------------

export const UserAccountCreate = async item => {
  const basx = base + '/users/create'
  const head = {
    'Content-Type': 'application/json',
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const UserAccountDetails = async item => {
  const basx = base + '/users/details'
  const head = {
    'Content-Type': 'application/json',
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const UserAccountList = async item => {
  const basx = base + '/users/accounts'
  const head = {
    'Content-Type': 'application/json',
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const UserOnboardStatus = async item => {
  const basx = base + '/user/onboard/status'
  const head = {
    'Content-Type': 'application/json',
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const TeamMemberCreate = async item => {
  const basx = base + '/teams/member/create'
  const head = {
    'Content-Type': 'application/json',
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const TeamMemberDelete = async item => {
  const basx = base + '/teams/member/delete'
  const head = {
    'Content-Type': 'application/json',
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const TeamMemberList = async item => {
  const basx = base + '/teams/member/list'
  const head = {
    'Content-Type': 'application/json',
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const TeamContactCreate = async item => {
  const basx = base + '/teams/contact/create'
  const head = {
    'Content-Type': 'application/json',
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const TeamContactDelete = async item => {
  const basx = base + '/teams/contact/delete'
  const head = {
    'Content-Type': 'application/json',
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const TeamContactList = async item => {
  const basx = base + '/teams/contact/list'
  const head = {
    'Content-Type': 'application/json',
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const TeamContactCount = async item => {
  const basx = base + '/teams/contact/count'
  const head = {
    'Content-Type': 'application/json',
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const TeamContactDetails = async item => {
  const basx = base + '/teams/contact/details'
  const head = {
    'Content-Type': 'application/json',
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}
