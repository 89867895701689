// user account
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

import { NumberFormat } from "../../services/srvc-utilities";

import WebbDividerMedium from "../webx/webb-divider-md";
import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbIcon from "../webx/webb-icon";

import { GetUserForm } from '../../services/srvc-utilities'
import { GetLocalUser, GetLocalBusiness } from '../../services/srvc-auth-local'

import { AccountsBaseBalance } from "../../services/srvc-accounts-realm";

const media = 'https://img.freepik.com/free-vector/abstract-glowing-particles-background-blue-red-color_1017-26575.jpg?w=900'


export default function UserInfocardModule () {

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx
  
  const navigate = useNavigate();

  const [loader, setLoader] = useState(true);

  const [balance, setBalance] = useState({number: '000000', ticker: '******'})
  const [data, setData] = useState()

  useEffect( () => {

    if (asset){
      const fetchData = async() => {
        setLoader(true);
        const result = await AccountsBaseBalance({data: {user: asset.item}, srvc: '******'})
        // console.log (result) 

        if (result.stat) {
          setBalance(result.data.balance)
          setData(result.data)
        }


        setLoader(false);
      }
      fetchData()
    } else {}
  },[asset]);

  // if (loader) return <WebbLoaderSmall/>


  return (
  <>
    {/* info */}
    <div className="mb-3 d-none">
      <h2 className='text-lead m-0 mx-3'>{'Account Details'}</h2>
      <p className='text-normal m-0'></p>
    </div>
    
    {/* data */}
    <div className="rounded-xd text-color-wite shadow"
      style={{
        backgroundImage:`url(${media})`, 
        backgroundRepeat:'no-repeat', 
        backgroundSize:'cover',
        backgroundPosition: 'center center',
        backgroundColor: 'rgba(0, 0, 0, 0.75)'
        // minHeight: '100vh'
        // height:'100vh'
      }}
    >
      <div className="rounded-xd p-3" style={{backgroundColor: 'rgba(0, 0, 0, 0.5)'}}>

      <div className="d-flex" style={{}}>
        <div className="me-auto d-none">
          <p className="text-small m-0 d-none">Account Balance</p>
          <p className="m-0 d-none d-md-block">
            <span className="text-header-md">{NumberFormat(balance?.number/1000000 ||'0','', '2')}</span>
            <span className="ms-1"></span>
            <span className="text-small">{balance?.ticker || '***'}</span>
          </p>
          <p className="m-0 d-md-none">
            <span className="text-header-sm">{NumberFormat(balance?.number/1000000 ||'0','', '2')}</span>
            <span className="ms-1"></span>
            <span className="text-small">{balance?.ticker || '***'}</span>
          </p>
          <p className="m-0 d-none">{data?.account?.number || '***'}</p>
        </div>

        <div className="rounded-xx pt-1" style={{height: '2.5rem', width: '2.5rem'}}>
          {/* <Jazzicon diameter={33} seed={jsNumberForAddress(asset.item ??= Date.now().toString())} />  */}
          <WebbIcon data={{ color: 'text-color-wite', size: 'text-icon-sm'}}/>
        </div>
      </div>      

      <WebbDividerMedium />
      <WebbDividerSmall />
      <p className="text-header-sm m-0">{(asset?.number || '0000000000000000').match(/.{1,4}/g).join(" ")}</p>
      
      <WebbDividerSmall />
      <p className="text-normal text-sm text-bold m-0">{asset?.name || '******'}</p>
      <p className="m-0">{asset?.mail || '******'}</p>
      <p className="text-small m-0">Status: {asset?.active ? 'Active' : 'Inactive'}</p>
      </div>

    </div>

    <div className="text-small mt-1 text-color-lite mx-3">
      {asset?.item || '******'}
    </div>

  </>

  )
}