// main
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

import ContentFormat from "../content/webz/content-format-xx";
import WebbHeader from "../content/webz/webb-header-xx";
import WebbFooterMobile from "../content/webz/webb-footer-mobile";

import WebbDividerMedium from "../content/webx/webb-divider-md";
import WebbDividerSmall from "../content/webx/webb-divider-sm";


import UserInfocardModule from "../content/user/user-infocard";
import UserAccountActionsModule from "../content/user/user-account-actions";

import UserAccountsListModule from "../content/accounts/accounts-list";

// import UserAccountXModule from "../content/home/user-acount-x";


export default function TeamDetails () {
  
  const metadata = {
    name: 'Profile',
    banner: {link: 'https://img.freepik.com/premium-vector/futuristic-vector-hexagon-wave-dark-cyberspace-abstract-wave-with-dots-line-white-moving-particles-background_744733-97.jpg?w=900'}
  }

  return(

  <>
    <Helmet>
      <title>{metadata.name}{' • '}{process.env.REACT_APP_WEBB_SITE_NAME}{' • '}{process.env.REACT_APP_WEBB_SITE_LINE}</title>
      <link rel="canonical" href={process.env.REACT_APP_WEBB_SITE_LINK} />
    </Helmet>

    <ContentFormat 
      
      name = {metadata.name}
      header = {{ size: 'small', visible: true, data: 
        <> 
          <WebbHeader data={{name: metadata.name, home: '/', link:'home'}} /> 
        </> }}

      media = {{ size: 'small', data: <></> }}

      content = {{ size: 'small', data: 
      <>
        <WebbDividerSmall />
        <h2 className='text-normal m-0 mx-3'>{'Account Details'}</h2>
        <WebbDividerSmall />
        <UserInfocardModule />
        
        <WebbDividerSmall />
        {/* <UserAccountActionsModule /> */}


        <WebbDividerMedium />
        <WebbDividerMedium />
        <div className="mx-3">
          {/* <UserAccountXModule /> */}
        </div>
        
        
        <WebbDividerMedium />
        <WebbDividerMedium />
        <WebbDividerMedium />
        <WebbDividerMedium />
        <WebbDividerMedium />
        <WebbDividerMedium />
      </>
      }}
    
      footer = {{ size: 'small', data: 
      <> 
        <div className="">

        </div>
      </> }}
    
    ></ContentFormat>


  </>
  )
}