// auth - firebase mail link
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { NumberFormat } from '../../services/srvc-utilities'

import WebbDividerSmall from '../webx/webb-divider-sm'
import WebbDividerMedium from '../webx/webb-divider-md'

import { GetUserForm } from '../../services/srvc-utilities'
import { GetLocalUser, GetLocalBusiness } from '../../services/srvc-auth-local'

import UnitActionsModule from './units-actions'
import listStatus from '../../data.static/data-transfers-status.json'

import { AssetUnitDetailsCreator } from '../../services/srvc-assets-realm'


export default function UnitDetailsCreatorModule (props) {
  

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const navigate = useNavigate()
  const {id} = useParams()

  const [loader, setLoader] = useState(true)
  
  const [list, setList] = useState(listStatus.data)
  const [data, setData] = useState({})


  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true)

        const result = await AssetUnitDetailsCreator({
          data: { item: id }
        })
        console.log(result)

        if (result.stat) setData(result.data)

        setLoader(false)
      }
      fetchData()
    } else {}
  }, [])

  if (loader) return <></>

  return (
    <>
      
      {/* data */}
      <div className=''>
        <div className='media-standard rounded-xd'>
          <img className='w-100 rounded-xd shadow' src = {data.media.link} alt="..."></img>
        </div>
        
        <div className={`d-none rounded-xd rounded-top-none mx-3 back-color-${data.status.mint && !data.status.burn ? 'success' : 'error'}`}>
          <p className='text-uppercase text-small m-0 text-center text-color-wite p-2'>
            {data.status.mint && !data.status.burn ? 'active' : 'inactive'}
          </p>
        </div>
      </div>

      <div className='mb-1'></div>
      <div className='back-color-wite p-3 rounded-xd'>
        <p className='text-normal text-bold m-0 text-truncate'>{data.meta.name}</p>
        <p className='text-small m-0 mb-2'>{data.number}</p>
        
        <p className='m-0'>{data.meta.memo}</p>
        <WebbDividerSmall />
        <div className=''>

          <span className='text-small m-0'>{data.creator.name}</span>
        </div>
      </div>

      {/* actions */}
      <div className=''>
        <div className='mb-1'></div>
        <UnitActionsModule data={data}/>
      </div>

    </>
  )
}
