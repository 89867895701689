// routes
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../context/authcontext";

// views - main
import Main from "../views.xz/main-xz";


// views - home
import HomeUser from "../views.home/home-user";
import HomeTeam from "../views.home/home-team";


// views - assets
import Assets from "../views.assets/assets";
import UnitDetailsCreator from "../views.assets/units-details-creator";

// views - transfers
import Transfers from "../views.transfers/transfers";


// views - user
import UserDetails from "../views.user/user-details";
import TeamDetails from "../views.user/team-details";

// views - scan
import UnitActionsScan from "../views.assets/units-actions-scan";


// views - onboard
import UserOnboardName from '../views.onboard/user-onboard-name'
import UserOnboardIdentiti from "../views.onboard/user-onboard-id";
import UserOnboardMinter from "../views.onboard/user-onboard-minter";
import UserOnboardHold from "../views.onboard/user-onboard-hold";

// views - auth
import AuthMailCode from "../views.auth/auth-mail-code";
import AuthNext from "../views.auth/auth-next";
import AuthSessionX from "../views.auth/auth-session-x";


const routes = [

  { route:'/', content: <Main />, auth:false },

  // user
  { route:'/user/home', content: <HomeUser />, auth:true },
  { route:'/team/home', content: <HomeTeam />, auth:true },

  // markets

  
  // assets
  { route:'/team/assets', content: <Assets />, auth:true },
  { route:'/team/units/:id', content: <UnitDetailsCreator />, auth:true },

  

  // network
  

  // transfers
  { route:'/team/transfers', content: <Transfers />, auth:true },



  // scan
  { route:'/team/units/:id/scan', content: <UnitActionsScan />, auth:true },

  
  // user
  { route:'/team/account', content: <TeamDetails />, auth:true },


  // onboard
  { route:'/user/onboard', content: <UserOnboardName />, auth: true },
  { route:'/user/onboard/id', content: <UserOnboardIdentiti />, auth: true },
  { route:'/user/onboard/minter', content: <UserOnboardMinter />, auth: true },
  { route:'/user/onboard/hold', content: <UserOnboardHold />, auth: true },

  // auth
  { route:'/auth', content: <AuthMailCode />, auth: false },
  { route:'/auth/next', content: <AuthNext />, auth: true },
  { route:'/auth/x', content: <AuthSessionX />, auth: true },
  
]


export default function RouteX() {

  const { user } = useAuth();
  // console.log (user)

  return (
    <Routes>
      {routes.map ((item,i)=>(item.auth
        ? <Route key={i} path={item.route} element={!user ? <Navigate to='/' replace /> : item.content} />
        : <Route key={i} path={item.route} element={item.content} />
      ))}
    </Routes>
  );
}